<template>
    <v-form>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-select
              :error-messages="typeErrors"
              :label="$t('addressType')"
              @blur="$v.item.type.$touch()"
              @input="$v.item.type.$touch()"
              required
              :items="typeValues"
              v-model="item.type"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              :error-messages="streetNrErrors"
              :label="$t('streetNr')"
              @blur="$v.item.streetNr.$touch()"
              @input="$v.item.streetNr.$touch()"
              v-model="item.streetNr"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              :error-messages="additionalErrors"
              :label="$t('additional')"
              @blur="$v.item.additional.$touch()"
              @input="$v.item.additional.$touch()"
              v-model="item.additional"
            />
          </v-col>

        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="4">
            <v-text-field
              :error-messages="zipCodeErrors"
              :label="$t('zipCode')"
              @blur="$v.item.zipCode.$touch()"
              @input="$v.item.zipCode.$touch()"
              required
              v-model="item.zipCode"
            />
          </v-col>
          <v-col cols="12" md="6" sm="8">
            <v-text-field
              :error-messages="cityErrors"
              :label="$t('city')"
              @blur="$v.item.city.$touch()"
              @input="$v.item.city.$touch()"
              v-model="item.city"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
</template>

<script>
  import { validationMixin } from 'vuelidate';
  import has from 'lodash/has';
  import { required } from 'vuelidate/lib/validators';

  export default {
    name: 'AddressForm',
    mixins: [validationMixin],
    props: {
      values: {
        type: Object,
        required: true
      },
      errors: {
        type: Object,
        default: () => {}
      },

      loading: {
        type: Boolean,
        required: true
      },

      initialValues: {
        type: Object,
        default: () => {}
      },

      handleSubmit: {
        type: Function,
        required: false
      },

      handleReset: {
        type: Function,
        required: false
      },
    },
    mounted () {},
    data () {
      return {
        type: null,
        streetNr: null,
        additional: null,
        zipCode: null,
        city: null,
        country: 'DE',
        typeValues: [
          {text: this.$t('defaultAddress'), value: 'default'},
          {text: this.$t('privateAddress'), value: 'private'},
          {text: this.$t('businessAddress'), value: 'business'},
          {text: this.$t('invoiceAddress'), value: 'invoice'},
          {text: this.$t('deliveryAddress'), value: 'delivery'}
        ],
      };
    },
    computed: {
      item () {
        return this.initialValues || this.values;
      },
      typeErrors () {
        const errors = [];

        if (!this.$v.item.type.$dirty) {
          return errors;
        }

        has(this.violations, 'type') && errors.push(this.violations.type);

        !this.$v.item.type.required && errors.push(this.$t('Field is required'));

        return errors;
      },
      streetNrErrors () {
        const errors = [];

        if (!this.$v.item.streetNr.$dirty) {
          return errors;
        }

        has(this.violations, 'streetNr') && errors.push(this.violations.streetNr);

        !this.$v.item.streetNr.required && errors.push(this.$t('Field is required'));

        return errors;
      },
      additionalErrors () {
        const errors = [];

        if (!this.$v.item.additional.$dirty) {
          return errors;
        }
        has(this.violations, 'additional') && errors.push(this.violations.additional);

        return errors;
      },
      zipCodeErrors () {
        const errors = [];

        if (!this.$v.item.zipCode.$dirty) {
          return errors;
        }
        has(this.violations, 'zipCode') && errors.push(this.violations.zipCode);
        !this.$v.item.zipCode.required && errors.push(this.$t('Field is required'));

        return errors;
      },
      cityErrors () {
        const errors = [];

        if (!this.$v.item.city.$dirty) {
          return errors;
        }
        has(this.violations, 'city') && errors.push(this.violations.city);
        !this.$v.item.city.required && errors.push(this.$t('Field is required'));

        return errors;
      },
      violations () {
        return this.errors || {};
      }
    },
    methods: {
      submitItem () {
        if (this.handleSubmit) {
          this.handleSubmit();
        }
      },
      resetItem () {
        if (this.handleReset) {
          this.handleReset();
        }
      }
    },
    validations: {
      item: {
        type: {
          required
        },
        streetNr: {
          required
        },
        additional: {},
        zipCode: {
          required
        },
        city: {
          required
        },
      }
    },
  };
</script>
