<template>
    <div>
        <Toolbar
                :handle-delete="del"
        />
        <AddressForm
                 :errors="violations"
                :values="item"
                ref="updateForm"
                :handle-reset="resetForm"
                :handle-submit="onSendForm"
                :loading="isLoading"
                v-if="item"
        />
        <Loading :visible="isLoading || deleteLoading"/>
    </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { mapFields } from 'vuex-map-fields';
  import AddressForm from '../../components/address/Form.vue';
  import Loading from '../../components/Loading';
  import Toolbar from '../../components/Toolbar';
  import UpdateMixin from '../../mixins/UpdateMixin';

  const servicePrefix = 'Address';

  export default {
    name: 'AddressUpdate',
    servicePrefix,
    mixins: [UpdateMixin],
    data(){
      return {
        updateMessage: this.$t('addressUpdateSuccess'),
        updateRoute: null
      };
    },
    components: {
      Loading,
      Toolbar,
      AddressForm
    },
    computed: {
      ...mapFields('address', {
        deleteLoading: 'isLoading',
        isLoading: 'isLoading',
        error: 'error',
        deleted: 'deleted',
        updated: 'updated',
        violations: 'violations'
      }),
      ...mapGetters('address', ['find'])
    },
    methods: {
      ...mapActions('address', {
        deleteItem: 'del',
        retrieve: 'load',
        update: 'update',
      }),
    },
    watch: {
      item(){
        this.updateRoute = {
          name: `${this.$options.servicePrefix}Show`,
          params: { id: this.item['@id'] }
        }
      }
    },
  }
</script>
